exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ebooki-o-zdrowiu-tsx": () => import("./../../../src/pages/ebooki-o-zdrowiu.tsx" /* webpackChunkName: "component---src-pages-ebooki-o-zdrowiu-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-forgot-username-tsx": () => import("./../../../src/pages/forgot-username.tsx" /* webpackChunkName: "component---src-pages-forgot-username-tsx" */),
  "component---src-pages-icd-10-szukaj-tsx": () => import("./../../../src/pages/icd-10/szukaj.tsx" /* webpackChunkName: "component---src-pages-icd-10-szukaj-tsx" */),
  "component---src-pages-icd-10-tsx": () => import("./../../../src/pages/icd-10.tsx" /* webpackChunkName: "component---src-pages-icd-10-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-konsultacja-lekarska-online-index-tsx": () => import("./../../../src/pages/konsultacja-lekarska-online/index.tsx" /* webpackChunkName: "component---src-pages-konsultacja-lekarska-online-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-opinie-tsx": () => import("./../../../src/pages/opinie.tsx" /* webpackChunkName: "component---src-pages-opinie-tsx" */),
  "component---src-pages-prywatne-ubezpieczenie-zdrowotne-index-tsx": () => import("./../../../src/pages/prywatne-ubezpieczenie-zdrowotne/index.tsx" /* webpackChunkName: "component---src-pages-prywatne-ubezpieczenie-zdrowotne-index-tsx" */),
  "component---src-pages-user-index-tsx": () => import("./../../../src/pages/user/index.tsx" /* webpackChunkName: "component---src-pages-user-index-tsx" */),
  "component---src-pages-user-profile-tsx": () => import("./../../../src/pages/user/profile.tsx" /* webpackChunkName: "component---src-pages-user-profile-tsx" */),
  "component---src-pages-zaloz-konto-tsx": () => import("./../../../src/pages/zaloz-konto.tsx" /* webpackChunkName: "component---src-pages-zaloz-konto-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-authors-overview-tsx": () => import("./../../../src/templates/authors-overview.tsx" /* webpackChunkName: "component---src-templates-authors-overview-tsx" */),
  "component---src-templates-calculator-tsx": () => import("./../../../src/templates/calculator.tsx" /* webpackChunkName: "component---src-templates-calculator-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-icd-10-alphabet-tsx": () => import("./../../../src/templates/icd10/alphabet.tsx" /* webpackChunkName: "component---src-templates-icd-10-alphabet-tsx" */),
  "component---src-templates-icd-10-category-tsx": () => import("./../../../src/templates/icd10/category.tsx" /* webpackChunkName: "component---src-templates-icd-10-category-tsx" */),
  "component---src-templates-icd-10-group-tsx": () => import("./../../../src/templates/icd10/group.tsx" /* webpackChunkName: "component---src-templates-icd-10-group-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-overview-landing-page-tsx": () => import("./../../../src/templates/overview-landing-page.tsx" /* webpackChunkName: "component---src-templates-overview-landing-page-tsx" */),
  "component---src-templates-preview-tsx": () => import("./../../../src/templates/preview.tsx" /* webpackChunkName: "component---src-templates-preview-tsx" */),
  "component---src-templates-qa-listing-tsx": () => import("./../../../src/templates/qa-listing.tsx" /* webpackChunkName: "component---src-templates-qa-listing-tsx" */),
  "component---src-templates-qa-tsx": () => import("./../../../src/templates/qa.tsx" /* webpackChunkName: "component---src-templates-qa-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-templates-subcategory-tsx": () => import("./../../../src/templates/subcategory.tsx" /* webpackChunkName: "component---src-templates-subcategory-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

